//@ts-check
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Tarjeta from "../tarjeta/Tarjeta";
import "./funcionamiento.css";
import Title from "../title/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const videos = [
  {
    title: "¿Que es Bonus Ticket?",
    videoURL: "https://www.youtube.com/embed/4otx2MEOpiY"
  },
  {
    title: "Nueva tarjeta Bonus",
    videoURL: "https://www.youtube.com/embed/JFBFI6JBQy0"
  },
  {
    title: "Bonus cuponera",
    videoURL: "https://www.youtube.com/embed/WCsTAioRIP8"
  }
];

const Funcionamiento = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <div id="funcionamiento" className="funcionamiento">
      <Title>Como funciona</Title>
      <Slider {...settings}>
        {videos.map(video => (
          <div key={video.title} className="funcionamiento-content__video__container">
            <Title>{video.title}</Title>
            <div className="funcionamiento-content">
              <div className="funcionamiento-content__video">
                <iframe
                  title="video-funcionamiento"
                  width="100%"
                  height="290"
                  src={video.videoURL}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {show &&
        (window.innerWidth > 600 ? (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Historial tarjeta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tarjeta handleClose={handleClose} />
            </Modal.Body>
          </Modal>
        ) : (
          <Tarjeta handleClose={handleClose} />
        ))}
    </div>
  );
};

export default Funcionamiento;
