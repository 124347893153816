import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import baseUrl from "../../utils/BaseUrl";
import { Card, Button, Modal } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import "./customCard.css";

const CustomCard = ({ card }) => {
  const [comercio, setComercio] = useState({});
  const [show, setShow] = useState(false);

  const getComercio = async idComercio => {
    try {
      const response = await axios.get(
        `${baseUrl()}/comercio/${idComercio}`
      );
      setComercio(response.data.respuesta[0]);
    } catch (e) {
      throw e;
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    getComercio(card.idComercio);
  };

  return (
    <>
      <Card className="custom-card" onClick={handleShow}>
        <div className="image">
          <Card.Img
            variant="top"
            src={card.imagenSecundaria}
          />
          {card.aceptaTarjeta ? <p>Acepta Tarjeta</p> : null}
        </div>
        <Card.Body className="custom-card__body">
          <Card.Title className="custom-card__title">
            {card.razonSocial}
          </Card.Title>
          <Card.Text className="custom-card__text">{card.descuento}</Card.Text>
        </Card.Body>
      </Card>
      {Object.keys(comercio).length ? (
        <Modal show={show} onHide={handleClose} className="card-modal">
          <Modal.Header>
            <Modal.Title>{comercio.razonSocial}</Modal.Title>
            <img
              src={comercio.imagenPrimaria}
              alt="logo-comercio"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              <div className="modal-body__right">
                <p>
                  <b>Acepta tarjeta:</b>{" "}
                  {comercio.aceptaTarjeta ? <FaCheck /> : <FaTimes />}
                </p>
                <p>
                  <b>Acepta cupón:</b>{" "}
                  {comercio.aceptaCupon ? <FaCheck /> : <FaTimes />}
                </p>
                <p>
                  <b>Categorías:</b> {comercio.categoria.toUpperCase()}
                </p>
                <p>
                  <b>Direcciones:</b> {comercio.direccion.toUpperCase()}
                </p>
                <p>
                  <b>Teléfonos:</b> {comercio.telefono}
                </p>
                {comercio.pageUrl && (
                  <p>
                    <b>Página WEB:</b>{" "}
                    <a
                      href={comercio.pageUrl}
                      style={{ color: "red" }}
                      target="_blank"
                    >
                      Ir al sitio
                    </a>
                  </p>
                )}
              </div>
              <div className="modal-body__left">
                <p>
                  <b>Descuento:</b> {comercio.descuento}
                </p>
                <p>
                  <b>Descripción:</b> {comercio.descripcion}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

CustomCard.propTypes = {
  card: PropTypes.object.isRequired
};

export default CustomCard;
