import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomSnackbar from "../customSnackbar/CustomSnackbar";
import { Button, TextField, Checkbox, Drawer } from "@material-ui/core";
import { FiAlertCircle } from "react-icons/fi";
import axios from "axios";
import baseUrl from "../../utils/BaseUrl";
import "./adhesionForm.css";
import TerminosCondiciones from "./TerminosCondiciones";

const AdhesionForm = ({ handleClose }) => {
  const [values, setValues] = useState({
    nombre: "",
    apellido: "",
    dni: "",
    domicilio: "",
    telefono: "",
    email: "",
    fechaNacimiento: "",
    estadoCivil: "",
    empresa: "",
    domicilioLaboral: "",
    entidad: "",
    numeroTarjeta: "",
    banco: "",
    cbu: "",
    aceptarTerminos: false
  });
  const [isOpen, setIsOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = () => setOpenDrawer(true);

  const handleCloseDrawer = () => setOpenDrawer(false);

  const sendMail = async () => {
    if (validateFields()) {
      try {
        await axios.post(`${baseUrl()}/mail/adhesion`, {
          values
        });
        setMensaje("OK. Email enviado con éxito.");
        setValues({
          nombre: "",
          apellido: "",
          dni: "",
          domicilio: "",
          telefono: "",
          email: "",
          fechaNacimiento: "",
          estadoCivil: "",
          empresa: "",
          domicilioLaboral: "",
          entidad: "",
          numeroTarjeta: "",
          banco: "",
          cbu: "",
          aceptarTerminos: false
        });
        setIsOpen(true);
      } catch (e) {
        throw e;
      }
    } else {
      setMensaje("ERROR. Todos los campos del formulario son obligatorios.");
      setIsOpen(true);
    }
  };

  const handleChange = name => event =>
    setValues({ ...values, [name]: event.target.value });

  const validateFields = () => {
    if (
      !values.nombre ||
      !values.apellido ||
      !values.dni ||
      !values.domicilio ||
      !values.telefono ||
      !values.email ||
      !values.fechaNacimiento ||
      !values.estadoCivil ||
      !values.empresa ||
      !values.domicilioLaboral ||
      !values.entidad ||
      !values.numeroTarjeta ||
      !values.banco ||
      !values.cbu ||
      !values.aceptarTerminos
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="adhesion-form">
      {/* <span style={{ fontSize: 20, marginBottom: 30 }}>
        Abono mensual de $x. Te permite acceder a 4 tarjetas ¡para repartir con tu familia!
        En caso de adhesión para EMPRESAS o PyMES, consultar plan.
      </span> */}
      <form>
        <div className="adhesion-form__personal">
          <h5>Datos personales</h5>
          <TextField
            label="Nombre"
            value={values.name}
            onChange={handleChange("nombre")}
            margin="normal"
            required
            autoFocus
          />
          <TextField
            label="Apellido"
            value={values.apellido}
            onChange={handleChange("apellido")}
            margin="normal"
            required
          />
          <TextField
            label="DNI"
            value={values.dni}
            onChange={handleChange("dni")}
            margin="normal"
            required
          />
          <TextField
            label="Domicilio"
            value={values.domicilio}
            onChange={handleChange("domicilio")}
            margin="normal"
            required
          />
          <TextField
            label="Teléfono"
            value={values.telefono}
            onChange={handleChange("telefono")}
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={values.email}
            onChange={handleChange("email")}
            margin="normal"
            required
          />
          <TextField
            label="Fecha Nacimiento (dd/mm/aaaa)"
            value={values.fechaNacimiento}
            onChange={handleChange("fechaNacimiento")}
            margin="normal"
            required
          />
          <TextField
            label="Estado Civil"
            value={values.estadoCivil}
            onChange={handleChange("estadoCivil")}
            margin="normal"
            required
          />
          <TextField
            label="Empresa en la que trabaja"
            value={values.empresa}
            onChange={handleChange("empresa")}
            margin="normal"
            required
          />
          <TextField
            label="Domicilio Laboral"
            value={values.domicilioLaboral}
            onChange={handleChange("domicilioLaboral")}
            margin="normal"
            required
          />
        </div>
        <div className="adhesion-form__cobranza">
          <div className="credito">
            <h5>Tarjeta de crédito</h5>
            <TextField
              label="Entidad"
              value={values.entidad}
              onChange={handleChange("entidad")}
              margin="normal"
              required
            />
            <TextField
              label="Número de tarjeta"
              value={values.numeroTarjeta}
              onChange={handleChange("numeroTarjeta")}
              margin="normal"
              required
            />
          </div>
          <div className="debito">
            <h5>Débito en cuenta</h5>
            <TextField
              label="Banco"
              value={values.banco}
              onChange={handleChange("banco")}
              margin="normal"
              required
            />
            <TextField
              label="CBU"
              value={values.cbu}
              onChange={handleChange("cbu")}
              margin="normal"
              required
            />
          </div>
        </div>
      </form>
      <div className="terminos-condiciones__checkbox ">
        <Checkbox value={values.aceptarTerminos} />
        Acepto
            <span
          style={{ color: "red", marginLeft: "2px" }}
          onClick={handleOpenDrawer}
        >
          términos y condiciones de uso
            </span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <FiAlertCircle style={{ fontSize: 20, color: 'orange', marginRight: 10 }} />
        La solicitud de adhesión queda pendiente hasta la confirmación de Bonusticket.
      </div>
      <div className="form-actions">
        <Button onClick={handleClose}>Cerrar</Button>
        <Button type="submit" onClick={sendMail}>
          Suscribirse
        </Button>
      </div>
      <Drawer
        className="adhesion-drawer"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <TerminosCondiciones handleCloseDrawer={handleCloseDrawer} />
      </Drawer>
      <CustomSnackbar isOpen={isOpen} setIsOpen={setIsOpen} mensaje={mensaje} />
    </div>
  );
};

AdhesionForm.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default AdhesionForm;
