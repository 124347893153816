//@ts-check
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { TiCreditCard } from "react-icons/ti";
import "./interes.scss";
import Modal from "react-bootstrap/Modal";
import AdhesionForm from "../adhesion/AdhesionForm";
import Tarjeta from "../tarjeta/Tarjeta";
import ContactoForm from "../contacto/ContactoForm";
import Title from "../title/Title";
import { Card } from "react-bootstrap";

const Interes = () => {
  const [showAdhesion, setShowAdhesion] = useState(false);
  const [showContacto, setShowContacto] = useState(false);
  const [showTarjeta, setShowTarjeta] = useState(false);

  const toggleShowAdhesion = () => setShowAdhesion(state => !state);

  const toggleShowContacto = () => setShowContacto(state => !state);

  const toggleShowTarjeta = () => setShowTarjeta(state => !state);

  return (
    <>
      <div className="interes__container">
        <div className="sumate__container">
          <Title style={{ marginTop: 30 }}>Sumate a bonus</Title>
          <div className="interes__links">
            <Card style={{ width: 175 }} onClick={toggleShowContacto}>
              <Card.Body style={{ padding: 0, cursor: "pointer" }}>
                <Card.Img variant="top" src="img/contacto.jpg" />
              </Card.Body>
            </Card>
            <Card style={{ width: 175 }} onClick={toggleShowAdhesion}>
              <Card.Body style={{ padding: 0, cursor: "pointer" }}>
                <Card.Img variant="top" src="img/adhesion.jpg" />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="consulta__container">
            <Title style={{ marginTop: 30 }}>Consulta tú estado</Title>
          <div className="consulta__links">
            <Card style={{ width: 200 }} onClick={toggleShowTarjeta}>
              <Card.Body style={{ padding: 0, cursor: "pointer" }}>
                <Card.Img variant="top" src="img/estadoTarjeta.jpg" />
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {showAdhesion &&
        (window.innerWidth > 600 ? (
          <Modal show={showAdhesion} onHide={toggleShowAdhesion}>
            <Modal.Header>
              <Modal.Title>Solicitud de adhesión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AdhesionForm handleClose={toggleShowAdhesion} />
            </Modal.Body>
          </Modal>
        ) : (
            <AdhesionForm handleClose={toggleShowAdhesion} />
          ))}
      {showContacto &&
        (window.innerWidth > 600 ? (
          <Modal show={showContacto} onHide={toggleShowContacto}>
            <Modal.Header>
              <Modal.Title>Formulario de contacto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContactoForm handleClose={toggleShowContacto} />
            </Modal.Body>
          </Modal>
        ) : (
            <ContactoForm handleClose={toggleShowContacto} />
          ))}
      {showTarjeta &&
        (window.innerWidth > 600 ? (
          <Modal show={showTarjeta} onHide={toggleShowTarjeta}>
            <Modal.Header>
              <Modal.Title>Historial tarjeta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tarjeta handleClose={toggleShowTarjeta} />
            </Modal.Body>
          </Modal>
        ) : (
            <Tarjeta handleClose={toggleShowTarjeta} />
          ))}
    </>
  );
};

export default Interes;
