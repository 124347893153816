import React, { useState } from "react";
import PropTypes from "prop-types";
import { Nav, Modal } from "react-bootstrap";
import AdhesionForm from "../../adhesion/AdhesionForm";
import Tarjeta from "../../tarjeta/Tarjeta";

const NavbarLinks = ({ handleClose }) => {
  const [showTarjeta, setShowTarjeta] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleCloseTarjeta = () => setShowTarjeta(false);

  const handleCloseForm = () => setShowForm(false);

  const handleShowTarjeta = () => setShowTarjeta(true);

  const handleShowForm = () => setShowForm(true);

  return (
    <>
      <Nav className="navbar-header__links">
        <Nav.Link href="#funcionamiento" onClick={handleClose}>
          FUNCIONAMIENTO
        </Nav.Link>
        <Nav.Link href="#obtenerlo" onClick={handleClose}>
          QUIENES PUEDEN TENERLO
        </Nav.Link>
        <Nav.Link href="#contacto" onClick={handleClose}>
          CONTACTO
        </Nav.Link>
        {window.innerWidth > 600 ? (
          <Nav.Link onClick={handleShowForm}>ADHERIRSE</Nav.Link>
        ) : (
          <Nav.Link href="#info" onClick={handleClose}>
            ADHERIRSE
          </Nav.Link>
        )}
        <Nav.Link href="#info" onClick={handleClose}>
          QUIENES SOMOS
        </Nav.Link>
        {window.innerWidth > 600 ? (
          <Nav.Link onClick={handleShowTarjeta}>TU TARJETA</Nav.Link>
        ) : (
          <Nav.Link href="#funcionamiento" onClick={handleClose}>
            TU TARJETA
          </Nav.Link>
        )}
      </Nav>
      {showForm &&
        (window.innerWidth > 600 ? (
          <Modal show={showForm} onHide={handleCloseForm}>
            <Modal.Header>
              <Modal.Title>Solicitud de adhesión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AdhesionForm handleClose={handleCloseForm} />
            </Modal.Body>
          </Modal>
        ) : (
          <AdhesionForm handleClose={handleCloseForm} />
        ))}
      {showTarjeta &&
        (window.innerWidth > 600 ? (
          <Modal show={showTarjeta} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Historial tarjeta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tarjeta handleClose={handleCloseTarjeta} />
            </Modal.Body>
          </Modal>
        ) : (
          <Tarjeta handleClose={handleCloseTarjeta} />
        ))}
    </>
  );
};

NavbarLinks.propTypes = {
  handleClose: PropTypes.func
};

export default NavbarLinks;
