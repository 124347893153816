import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";
import Historial from "./Historial";
import axios from "axios";
import baseUrl from "../../utils/BaseUrl";
import "./tarjeta.css";

const Tarjeta = ({ handleClose }) => {
  const [historial, setHistorial] = useState([]);
  const [codigo, setCodigo] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false);

  const getHistorial = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl()}/tarjeta/historia/${codigo}`
      );
      if (response.data.mensaje.substring(0, 2) !== "OK") {
        setLoading(false);
        setMensaje(response.data.mensaje);
      } else {
        setLoading(false);
        setHistorial(response.data.respuesta);
      }
    } catch (e) {
      throw e;
    }
  };

  const handleChange = e => setCodigo(e.target.value);

  return (
    <div className="tarjeta">
      <form className="tarjeta-search" onSubmit={getHistorial}>
        <div className="tarjeta-search__input">
          <TextField
            placeholder="Ingrese código de tarjeta"
            onChange={handleChange}
            required
          />
        </div>
        <div className="tarjeta-search__button">
          <Button type="submit" onClick={getHistorial}>
            <FaSearch />
          </Button>
        </div>
      </form>
      <Historial historial={historial} mensaje={mensaje} loading={loading} />
      <Button onClick={handleClose}>Cerrar</Button>
    </div>
  );
};

Tarjeta.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default Tarjeta;
