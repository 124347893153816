import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { TextField, Button } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";
import "./searchForm.css";

const SearchForm = ({ setSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const anchorRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    setSearch(searchValue);
    anchorRef.current.click();
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <TextField
        className="search-form__input"
        onChange={e => setSearchValue(e.target.value.trim())}
        placeholder="Ingrese el nombre del comercio o el rubro al que pertenece"
      />
      <Button onClick={handleSubmit} ref={anchorRef} href="#content">
        <FaSearch />
      </Button>
    </form>
  );
};

SearchForm.propTypes = {
  setSearch: PropTypes.func.isRequired
};

export default SearchForm;
