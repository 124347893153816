import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CustomCarousel from "../../carousel/CustomCarousel";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import "./header.css";

const Header = ({ breakpoint }) => {
  const [navBackground, setNavBackground] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const navRef = useRef();
  navRef.current = navBackground;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 500;
      navRef.current !== show && setNavBackground(show);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="top" className="showcase">
      <div
        className={
          navBackground || width < breakpoint
            ? "header black"
            : "header transparent"
        }
      >
        {width > breakpoint ? <DesktopHeader /> : <MobileHeader />}
      </div>
      <CustomCarousel />
    </div>
  );
};

Header.propTypes = {
  breakpoint: PropTypes.number.isRequired
};

export default Header;
