import React from "react";
import { Button } from "@material-ui/core";

const TerminosCondiciones = ({ handleCloseDrawer }) => (
  <div className="terminos-condiciones__content">
    <div className="terminos-condiciones__content-header">
      <Button onClick={handleCloseDrawer} color="secondary" style={{fontSize: 30}}>CERRAR</Button>
      <h3>TÉRMINOS Y CONDICIONES DE ACCESO Y USO DE BONUS TICKET</h3>
    </div>
    <ul className="terminos-condiciones__content-list">
      <li>
        <ul>
          <li className="bold-font">1. Objeto</li>
          <li className="remove-style">
            BONUS TICKET es un servicio que brinda a los adherentes (en
            adelante, TITULAR) la oportunidad de obtener distintos descuentos,
            obsequios, ventajas y/o promociones (en adelante, BENEFICIOS) para
            la adquisición de bienes y/o servicios en los comercios y/o
            prestadores de servicios adheridos a este sistema (en adelante,
            COMERCIOS). Con este fin, BONUS envia “CUPONES” a los TITULARes, los
            cuales le permitirá a los COMERCIOS poder identificar y hacer
            efectivo los BENEFICIOS, en los términos y condiciones aquí
            establecidas. Como contraprestación, BONUS TICKET, recibirá un pago
            mensual en dinero, en concepto de membresía y pertenencia. (en
            adelante el “PAGO”)
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">2. Principios Generales</li>
          <li className="remove-style">
            2.1 Es requisito indispensable para acceder a los BENEFICIOS ser
            miembro de BONUS TICKET.
          </li>
          <li className="remove-style">
            2.2 BONUS TICKET, enviara a sus socios miembros, de manera mensual
            “CUPONES”.
          </li>
          <li className="remove-style">
            2.3 El portador de “CUPONES” recibirá la oportunidad de obtener
            distintos BENEFICIOS en función de la utilización que de este
            realice, tanto en la adquisición de bienes y/o en la contratación de
            servicios que ofrezcan los COMERCIOS.
          </li>
          <li className="remove-style">
            2.4 En ningún caso y bajo ninguna circunstancia, estará permitido
            canjear los BENEFICIOS a otorgar por BONUS TICKET, por dinero en
            efectivo u otros BENEFICIOS no promocionados. BONUS TICKET no es un
            medio de crédito ni de pago.
          </li>
          <li className="remove-style">
            2.5 BONUS TICKET se reserva el derecho de modificar y/o variar los
            términos y condiciones del presente contrato.
          </li>
          <li className="remove-style">
            2.6 La adhesión a BONUS TICKET, implica la total aceptación de todas
            las cláusulas aquí establecidas, que se manifiestan conocidas por
            todos y cada uno de los adherentes, así como todas las
            modificaciones que resuelva BONUS TICKET en el futuro.
          </li>
          <li className="remove-style">
            2.7 BONUS TICKET se reserva el derecho de solicitar la devolución
            y/o proceder a la anulación de los CUPONES cuando, a su solo
            criterio, se haga un uso indebido o inconveniente de ésta.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">3. Del Pago</li>
          <li className="remove-style">
            3.1 El TITULAR, pagara de forma mensual, un abono de $(establecido
            en el frente) como contraprestación, por los descuentos y beneficios
            gestionados por BONUS TICKET.
          </li>
          <li className="remove-style">
            3.2 el pago por parte del TITULAR, se realizara de la forma y a
            traves de los medios que BONUS TICKET disponga.
          </li>
          <li className="remove-style">
            3.3 El TITUTAR Acepta que dicho abono/pago sea reajustado en función
            de los costos de BONUS TICKET, y en base a cualquier otro gravamen
            y/o recargo que BONUS TICKET tuviera que soportar.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">4. De los beneficios</li>
          <li className="remove-style">
            4.1. EL TITULAR, gozara de los BENFICIOS a través de CUPONES, que
            BONUS TICKET, enviara mensualmente al domicilio laboral, previamente
            informado por este.
          </li>
          <li className="remove-style">
            4.2.BONUS TICKET promocionará las ofertas propuestas por los
            COMERCIOS por medio de los CUPONES, y a través de publicaciones en
            www.bonusticket.com.ar o r los medios de comunicación que BONUS
            TICKET disponga.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">
            5. Cupones perdidos, dañados, robadas y/o hurtados
          </li>
          <li className="remove-style">
            5.1. El TITULAR deberá denunciar en forma inmediata a BONUS TICKET ,
            cualquier pérdida, deterioro sustancial, robo o hurto de su CUPON.
            Dicha denuncia deberá ser ratificada en forma personal y/o por
            notificación fehaciente dentro del plazo de 5 (cinco) días de
            producido el hecho. Ratificada la denuncia, BONUS TICKET procederá
            al reemplazo del/los CUPONES dentro de los 15 (quince) días hábiles,
            si existiese stock, cargando los costos a cargo del TITULAR.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">6. Vigencia de los Cupones</li>
          <li className="remove-style">
            6.1 BONUS TICKET se reserva el derecho de dar por finalizada la
            vigencia de cual fuese los CUPONES en curso y todos los BENEFICIOS
            que en su nombre se otorgaran, notificando tal decisión con la
            anticipación que razonablemente sea posible, por cualquier medio de
            comunicación que en su momento determine.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">
            7. Responsabilidad por los productos y servicios promocionados a
            través del Servicio
          </li>
          <li className="remove-style">
            7.1. En todos los casos, la responsabilidad de BONUS TICKET se
            limitará a la difusión y promoción de los productos y/o servicios
            ofrecidos por los COMERCIOS a los TITULARES de BONUS TICKET, y en
            ningún caso entregará garantía adicional de la que brindan los
            COMERCIOS.
          </li>
          <li className="remove-style">
            7.2 EL TITULAR en caso de reclamo por fallas de calidad,
            funcionamiento deberá dirigirse contra el COMERCIO.
          </li>
          <li className="remove-style">
            7.3. BONUS TICKET no asume responsabilidad alguna por: A) La calidad
            de los productos o servicios eventualmente ofrecidos por EL COMERCIO
            o contratados por el TITULAR. B) Los daños que se produzcan al
            TITULAR, y/o a sus bienes, a terceros beneficiarios de los productos
            o servicios y/o a sus bienes, por el cumplimiento o incumplimiento
            de las obligaciones del COMERCIO, cualquier otra causa imputable a
            los mismos, o por el riesgo o los vicios propios de la cosa
            transmitida. C) La imprecisión o alteración de los precios provistos
            en la oferta o promoción por parte de EL COMERCIO al TITULAR. D)
            Cualquier incumplimiento por parte del COMERCIO de las obligaciones
            que le impone la normativa aplicable. E) La solvencia de los
            TITULARES ni de los COMERCIOS.
          </li>
          <li className="remove-style">
            7.4 En caso de existir acciones legales o reclamos entre TITULARES y
            COMERCIOS, estos eximen a BONUS TICKET y sus empleados de toda
            responsabilidad y los mantendrán indemnes de todo costo en el que
            éstos deban incurrir por causa o en relación con su intervención, en
            cualquier calidad que le sea solicitada, en cualquier proceso legal
            o administrativo en el que debieren participar en conexión con dicho
            reclamo o acción legal.
          </li>
          <li className="remove-style">
            7.5 En virtud de lo expuesto, tanto el TITULAR como EL COMERCIO
            renuncian expresamente a realizar cualquier tipo de reclamo o acción
            judicial contra BONUS TICKET que tenga cualquier vinculación con las
            relaciones contractuales o extracontractuales que entre ellos se
            generen en relación al uso de los BENEFICIOS comunicados.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">8. De la Baja</li>
          <li className="remove-style">
            8.1 El TITULAR será dado de baja de BONUS TICKET: a) Automáticamente
            transcurridos tres meses de impago de la mensualidad; b) Cuando
            hiciera un uso indebido, de los BENEFICIOS ofrecidos en los
            COMERCIOS o a solo juicio de BONUS TICKET. c) Cuando así lo desee el
            TITULAR y lo comunique a contacto@bonusticket.com.ar, pero
            cumpliendo el plazo mínimo de 9 meses de permanencia, y comunicando
            dicha situación con 30 días de anticipación.
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li className="bold-font">9. Jurisdicción y Ley Aplicable</li>
          <li className="remove-style">
            9.1 Toda relación que se genere en virtud del uso del sistema y de
            BONUS TICKET entre las distintas partes descriptas en los términos y
            condiciones, será regida y concertada en total sujeción a las leyes
            de la República Argentina. Asimismo, resultarán competentes para
            dirimir cualquier controversia o procedimiento legal que surja los
            Tribunales ordinarios de la ciudad de San Miguel de Tucumán,
            cualquiera sea la causa de la acción o el reclamo, con renuncia
            expresa por parte suya a cualquier otra jurisdicción que pudiera
            corresponderle.
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

export default TerminosCondiciones;
