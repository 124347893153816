import React, { useState } from "react";
import FixedContact from "../fixedContact/FixedContact";
import Header from "./header/Header";
import Content from "./content/Content";
import Footer from "./footer/Footer";
import SearchForm from "../search/SearchForm";
import Interes from "../interes/Interes";
import "./layout.css";

const Layout = () => {
  const [search, setSearch] = useState("");

  return (
    <div id="app-root" className="container-fluid">
      <div style={{ position: "relative" }}>
        <SearchForm setSearch={setSearch} />
        <Header breakpoint={600} />
      </div>
      <Content search={search} />
      <Interes />
      <Footer />
      <FixedContact />
    </div>
  );
};

export { Layout };
