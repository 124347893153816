import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomCard from "../../card/CustomCard";
import { FiltroCategoria } from "../../filtro/FiltroCategoria";
import Funcionamiento from "../../funcionamiento/Funcionamiento";
import Title from "../../title/Title";
import { FaArrowDown, FaArrowUp, FaRedoAlt } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import baseUrl from "../../../utils/BaseUrl";
import Obtenerlo from "../../obtenerlo/Obtenerlo";
import "./content.css";

const Content = ({ search }) => {
  const [comerciosCard, setComerciosCard] = useState([]);
  const [filteredComercios, setFilteredComercios] = useState([]);

  useEffect(() => {
    getComercios();
  }, []);

  useEffect(() => {
    setFilteredComercios(
      comerciosCard.filter(
        card =>
          card.razonSocial.toLowerCase().includes(search.toLowerCase(), 0) ||
          card.categoria.toLowerCase().includes(search.toLowerCase(), 0)
      )
    );
  }, [search]);

  const getComercios = async () => {
    try {
      const response = await axios.get(
        `${baseUrl()}/comercio/listar/cards`
      );
      setComerciosCard(response.data.respuesta);
    } catch (e) {
      throw e;
    }
  };

  const filterCards = categoria => {
    setFilteredComercios(
      comerciosCard.filter(card => card.categoria.includes(categoria))
    );
  };

  const handleCargar = () => {
    setFilteredComercios(comerciosCard);
  };

  const handleEsconder = () => {
    setFilteredComercios([]);
  };

  return (
    <>
      {comerciosCard.length ? (
        <div id="content" className="content">
          <div className="container">
            <div className="content-cards">
              <Title>Comercios adheridos</Title>
              <FiltroCategoria filterCards={filterCards} />
              <div className="cards">
                {filteredComercios.length
                  ? filteredComercios.map(card => (
                      <CustomCard key={card.idComercio} card={card} />
                    ))
                  : comerciosCard.map((card, index) => {
                      if (window.innerWidth <= 600 || index <= 5) {
                        return <CustomCard key={card.idComercio} card={card} />;
                      } else {
                        return null;
                      }
                    })}
              </div>
              {filteredComercios.length !== comerciosCard.length ? (
                <div className="cards-cargar">
                  <p>Mostrar todas</p>
                  <FaArrowDown
                    onClick={handleCargar}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div className="cards-cargar">
                  <p>Mostrar menos</p>
                  <FaArrowUp
                    onClick={handleEsconder}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
              {window.innerWidth < 600 && (
                <div className="cards-reload">
                  <FaRedoAlt
                    onClick={handleCargar}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="app-spinner">
          <CircularProgress color="secondary" />
        </div>
      )}
      <Funcionamiento />
      <Obtenerlo />
    </>
  );
};

Content.propTypes = {
  search: PropTypes.string
};

export default Content;
