import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import "./customSnackbar.css";

const CustomSnackbar = ({ isOpen, setIsOpen, mensaje }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setIsOpen(false);
  };

  return (
    <div>
      <Snackbar
        className="custom-snackbar"
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{mensaje}</span>}
      />
    </div>
  );
};

CustomSnackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  mensaje: PropTypes.string.isRequired
};

export default CustomSnackbar;
