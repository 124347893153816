import React from "react";
import { Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const Historial = ({ historial, mensaje, loading }) => {
  return historial.length ? (
    !loading ? (
      <div className="historial-tarjeta">
      <div>
        <h5>Movimientos de tu tarjeta:</h5>
      </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Comercio</th>
              <th>Disponible</th>
            </tr>
          </thead>
          <tbody>
            {historial.map(linea => (
              <tr>
                <td>{linea.razonSocial}</td>
                <td>
                  {!linea.usada ? (
                    <FaCheck style={{ color: "green" }} />
                  ) : (
                    <FaTimes style={{ color: "red" }} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot valign="center">
              <tr style={{width: "100%", fontSize: "14px"}}>
                  <td colspan="2"> Tu tarjeta se reinciará todos los días a las 00:00 hs, para que puedas seguir disfrutando día a día los beneficios de nuestros comercios adheridos </td>
              </tr>
          </tfoot>
        </Table>
      </div>
    ) : (
      <div className="app-spinner">
        <CircularProgress color="secondary" />
      </div>
    )
  ) : (
    <p style={{ textAlign: "center", marginTop: "20px" }}>
      {mensaje ? mensaje : "..."}
    </p>
  );
};

Historial.propTypes = {
  historial: PropTypes.array,
  mensaje: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

export default Historial;
