import React, { useEffect, useState } from "react";
import { Carousel, Modal, Button } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { FaCheck, FaTimes } from "react-icons/fa";
import axios from "axios";
import baseUrl from "../../utils/BaseUrl";
import "./customCarousel.css";

const CustomCarousel = () => {
  const [comerciosPrimarios, setComerciosPrimarios] = useState([]);
  const [comercio, setComercio] = useState({});
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getComerciosPrimarias();
  }, []);

  const getComerciosPrimarias = async () => {
    try {
      const response = await axios.get(
        `${baseUrl()}/comercio/listar/ImgPrimarias`
      );
      setComerciosPrimarios(response.data.respuesta);
    } catch (e) {
      throw e;
    }
  };

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    setComercio(comerciosPrimarios[index]);
  };

  const handleSelect = (selectedIndex, e) => setIndex(selectedIndex);

  return (
    <>
      <Carousel className="custom-carousel" onSelect={handleSelect}>
        {comerciosPrimarios.length ? (
          comerciosPrimarios.map(comercio => (
            <Carousel.Item key={comercio.imagenPrimaria}>
              <img
                className="d-block w-100"
                src={comercio.imagenPrimaria}
                alt="slide"
                onClick={handleShow}
              />
            </Carousel.Item>
          ))
        ) : (
          <div className="app-spinner">
            <CircularProgress color="secondary" />
          </div>
        )}
      </Carousel>
      {Object.keys(comercio).length ? (
        <Modal show={show} onHide={handleClose} className="card-modal">
          <Modal.Header>
            <Modal.Title>{comercio.razonSocial}</Modal.Title>
            <img
              src={comercio.imagenPrimaria}
              alt="logo-comercio"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              <div className="modal-body__right">
                <p>
                  <b>Acepta tarjeta:</b>{" "}
                  {comercio.aceptaTarjeta ? <FaCheck /> : <FaTimes />}
                </p>
                <p>
                  <b>Acepta cupón:</b>{" "}
                  {comercio.aceptaCupon ? <FaCheck /> : <FaTimes />}
                </p>
                <p>
                  <b>Categorías:</b> {comercio.categoria.toUpperCase()}
                </p>
                <p>
                  <b>Direcciones:</b> {comercio.direccion.toUpperCase()}
                </p>
                <p>
                  <b>Teléfonos:</b> {comercio.telefono}
                </p>
                {comercio.pageUrl && (
                  <p>
                    <b>Página WEB:</b>{" "}
                    <a
                      href={comercio.pageUrl}
                      style={{ color: "red" }}
                      target="_blank"
                    >
                      Ir al sitio
                    </a>
                  </p>
                )}
              </div>
              <div className="modal-body__left">
                <p>
                  <b>Descuento:</b> {comercio.descuento}
                </p>
                <p>
                  <b>Descripción:</b> {comercio.descripcion}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default CustomCarousel;
