import React from "react";
import "./title.css";

const Title = ({ children, ...otherProps }) => {
  return (
    <div className="container" {...otherProps}>
      <h5 className="title">{children}</h5>
    </div>
  );
};
export default Title;
