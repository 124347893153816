//@ts-check
import React, { useState } from "react";
import "./obtenerlo.scss";
import { Button, Card, Modal } from "react-bootstrap";
import ContactoForm from "../contacto/ContactoForm";
import Title from "../title/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const arrayCards = [
  {
    firstTitle: "EMPRESAS - PYMES",
    img: "img/empresas.jpg",
    info: "PARA EMPRESAS Y PYMES QUE QUIEREN MOTIVAR A SUS EMPLEADOS",
    url: "contacto"
  },
  {
    firstTitle: "INDIVIDUOS - FAMILIA",
    img: "img/individuos.jpg",
    info: `PARA PERSONAS O PARTICULARES QUE QUIEREN HACER RENDIR MAS SU SUELDO`,
    url: "contacto"
  },
  {
    firstTitle: "CLUBES - SINDICATOS - PREPAGAS - COMERCIOS",
    img: "img/obrasSociales.jpg",
    info: `PARA INSTITUCIONES O EMPRESAS QUE QUIEREN FIDELIZAR A SUS CLIENTES, AFILIADOS, SOCIOS, ALUMNOS, ETC.`,
    url: "contacto"
  }
];

const Obtenerlo = () => {
  const [showModal, setShowModal] = useState(false);
  const [cardInformation, setCardInformation] = useState({});
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(state => !state);

  const toggleModal = () => setShowModal(state => !state);

  const handleInfo = ({ firstTitle, info }) => () => {
    setCardInformation({ firstTitle, info });
    toggleModal();
  };

  return (
    <>
      <div className="obtenerlo_container" id="obtenerlo">
        <Title style={{ display: "flex", justifyContent: "center" }}>
          ¿Quienes pueden obtenerlo?
        </Title>
        <Slider {...settings}>
          {arrayCards.map(card => (
            <div key={card.img} className="funcionamiento-content__video__container">
              <Card onClick={handleInfo(card)}>
                <Card.Body style={{ padding: 0 }}>
                  <Card.Img
                    style={{ marginTop: 0, marginBottom: 10 }}
                    variant="top"
                    src={card.img}
                  />
                  <Card.Title>{card.firstTitle}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
      {show &&
        (window.innerWidth > 600 ? (
          <Modal show={show} onHide={toggleShow}>
            <Modal.Header>
              <Modal.Title>Formulario de contacto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContactoForm handleClose={toggleShow} />
            </Modal.Body>
          </Modal>
        ) : (
          <ContactoForm handleClose={toggleShow} />
        ))}
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header>
          <Modal.Title>{cardInformation.firstTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="obtener-modal_body">
            <p>{cardInformation.info}</p>
            <Button variant="link" onClick={toggleShow}>
              Complete el formulario de contacto para mas información
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Obtenerlo;
