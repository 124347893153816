import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomSnackbar from "../customSnackbar/CustomSnackbar";
import { Button, TextField, MenuItem } from "@material-ui/core";
import axios from "axios";
import baseUrl from "../../utils/BaseUrl";
import "./contactoForm.css";

const tipoClientes = [
  {
    label: "EMPRESAS"
  },
  {
    label: "INDIVIDUO"
  },
  {
    label: "COMERCIO"
  }
]

const ContactoForm = ({ handleClose }) => {
  const [values, setValues] = useState({
    nombre: "",
    apellido: "",
    celular: "",
    email: "",
    tipoCliente: "",
    mensaje: ""
  });
  const [isOpen, setIsOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");

  const sendMail = async () => {
    if (validateFields()) {
      try {
        await axios.post(`${baseUrl()}/mail/contacto`, {
          values
        });
        setValues({
          nombre: "",
          apellido: "",
          celular: "",
          email: "",
          tipoCliente: "",
          mensaje: ""
        });
        setMensaje("OK. Email enviado con éxito.");
        setIsOpen(true);
      } catch (e) {
        throw e;
      }
    } else {
      setMensaje("ERROR. Todos los campos del formulario son obligatorios.");
      setIsOpen(true);
    }
  };

  const handleChange = name => event =>
    setValues({ ...values, [name]: event.target.value });

  const validateFields = () => {
    if (
      !values.nombre ||
      !values.apellido ||
      !values.celular ||
      !values.email ||
      !values.tipoCliente ||
      !values.mensaje
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="contacto-form">
      <form>
        <div className="contacto-form__personal">
          <h5>Datos personales</h5>
          <TextField
            label="Nombre"
            value={values.name}
            onChange={handleChange("nombre")}
            margin="normal"
            required
            autoFocus
          />
          <TextField
            label="Apellido"
            value={values.apellido}
            onChange={handleChange("apellido")}
            margin="normal"
            required
          />
          <TextField
            label="Celular"
            value={values.celular}
            onChange={handleChange("celular")}
            margin="normal"
            required
          />
          <TextField
            label="Email"
            value={values.email}
            onChange={handleChange("email")}
            margin="normal"
            required
          />
          <TextField
            select
            label="Seleccione una opción"
            value={values.tipoCliente}
            onChange={handleChange("tipoCliente")}
          >
            {tipoClientes.map(tipoCliente => (
              <MenuItem key={tipoCliente.label} value={tipoCliente.label}>
                {tipoCliente.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Mensaje"
            multiline
            rows="4"
            value={values.mensaje}
            onChange={handleChange("mensaje")}
            margin="normal"
            required
          />
        </div>
      </form>
      <div className="form-actions">
        <Button onClick={handleClose}>Cerrar</Button>
        <Button type="submit" onClick={sendMail}>
          Enviar mensaje
        </Button>
      </div>
      <CustomSnackbar isOpen={isOpen} setIsOpen={setIsOpen} mensaje={mensaje} />
    </div>
  );
};

ContactoForm.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ContactoForm;
