import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import NavbarLinks from "./NavbarLinks";

const DesktopHeader = () => (
  <div className="container">
    <Navbar bg="light" expand="lg" className="navbar-header">
      <div className="navbar-header__logo">
        <Nav.Link href="#top">
          <img
            src="http://www.bonusticket.com.ar/img/bonusticket.png"
            alt="app-logo"
          />
        </Nav.Link>
      </div>
      <NavbarLinks />
    </Navbar>
  </div>
);

export default DesktopHeader;
