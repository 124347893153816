import React from "react";
import Info from "./Info";
import Contacto from "./Contacto";
import "./footer.css";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="footer-content">
        <div className="footer-content_contact">
          <Info />
          <Contacto />
        </div>
          <img className="footer-content_brand" src="img/marca_tucuman.png"/>
      </div>
    </div>
  </div>
);

export default Footer;
