import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import zenscroll from "zenscroll";

zenscroll.setup(700, 50);

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
