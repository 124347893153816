//@ts-check
import React from "react";
import { Button } from "@material-ui/core";
import { FaWhatsapp, FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TiSocialInstagram } from "react-icons/ti";
import { AiOutlineToTop } from "react-icons/ai";
import "./fixedContact.scss";

const contactArray = [
  {
    name: "email",
    url: "mailto:contacto@bonusticket.com.ar",
    icon: MdEmail
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/bonusticket",
    icon: FaLinkedinIn
  },
  {
    name: "whatsapp",
    url: "https://wa.me/+5493813452997",
    icon: FaWhatsapp
  },
  {
    name: "instagram",
    url: "https://instagram.com/bonusticket",
    icon: TiSocialInstagram
  },
  {
    name: "facebook",
    url: "https://facebook.com/bonustickettucuman/",
    icon: FaFacebookF
  },
  {
    name: "top",
    url: "#top",
    icon: AiOutlineToTop
  }
];

const FixedContact = () => (
  <div className="fixed-contact__container">
    {contactArray.map(Contact => (
      <Button href={Contact.url} target="_blank" key={Contact.name}>
        <Contact.icon />
      </Button>
    ))}
  </div>
);

export default FixedContact;
