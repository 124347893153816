import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import Drawer from "@material-ui/core/Drawer";
import NavbarLinks from "./NavbarLinks";

const MobileHeader = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpen = () => setOpenDrawer(true);

  const handleClose = () => setOpenDrawer(false);

  return (
    <Navbar bg="light" expand="lg" className="navbar-header">
      <FaBars onClick={handleOpen} />
      <div className="navbar-header__logo">
        <Nav.Link href="#top">
          <img
            src="http://www.bonusticket.com.ar/img/bonusticket.png"
            alt="app-logo"
          />
        </Nav.Link>
      </div>
      <Drawer open={openDrawer} onClose={handleClose} className="side-navbar">
        <div className="drawer-logo__container">
          <img
            src="http://www.bonusticket.com.ar/img/bonusticket.png"
            alt="app-logo"
          />
        </div>
        <NavbarLinks handleClose={handleClose} />
      </Drawer>
    </Navbar>
  );
};

export default MobileHeader;
