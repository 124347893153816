import React from "react";
import {
  FaWhatsapp,
  FaInstagram,
  FaFacebook,
  FaEnvelope
} from "react-icons/fa";
import "./contacto.css";

const Contacto = () => (
  <div id="contacto" className="contacto">
    <h5>CONTACTO</h5>
    <div className="item whatsapp">
      <FaWhatsapp />
      <a href="https://wa.me/543813452997?text=Hola! Quisiera obtener mas información de Bonusticket" target="_blank">+54 381-345 2997</a>
    </div>
    <div className="item facebook">
      <FaFacebook />
      <a href="https://www.facebook.com/bonustickettucuman" target="_blank">
        facebook.com/bonustickettucuman
      </a>
    </div>
    <div className="item instagram">
      <FaInstagram />
      <a href="https://www.instagram.com/bonusticket" target="_blank">
        instagram.com/bonusticket
      </a>
    </div>
    <div className="item mail">
      <FaEnvelope />
      <a href="mailto:contacto@bonusticket.com.ar">contacto@bonusticket.com.ar</a>
    </div>
  </div>
);

export default Contacto;
