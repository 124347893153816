import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, CircularProgress } from "@material-ui/core";
import baseUrl from "../../utils/BaseUrl";
import "./filtroCategoria.css";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

const FiltroCategoria = ({ filterCards }) => {
  const [categorias, setcategorias] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getCategorias();
  }, []);

  const getCategorias = async () => {
    try {
      const response = await axios.get(`${baseUrl()}/categoria`);
      setcategorias(response.data.respuesta);
    } catch (e) {
      throw e;
    }
  };

  const handleFilter = categoria => {
    filterCards(categoria.categoria);
  };

  return (
    <div className="filtro-categorias">
      {categorias.length ? (
        categorias.map(categoria => (
          <Chip
            key={categoria.idCategoria}
            label={categoria.categoria.toUpperCase()}
            className={classes.chip}
            component="a"
            href="#chip"
            clickable
            onClick={() => handleFilter(categoria)}
            variant="outlined"
          />
        ))
      ) : (
        <div className="app-spinner">
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

FiltroCategoria.propTypes = {
  filterCards: PropTypes.func.isRequired
};

export { FiltroCategoria };
