import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AdhesionForm from "../../adhesion/AdhesionForm";
import "./info.css";

const Info = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <div id="info" className="info">
      <h5>¿QUIENES SOMOS?</h5>
      <p>
        Bonus Ticket, es una joven empresa tucumana fundada en el año 2013 con
        el objetivo de potenciar las ventas de comercios, y a la vez generar
        beneficios a los consumidores. Mensualmente llegamos a mas de 10.000
        personas en nuestras diferentes modalidades, cupón y tarjeta, trabajando
        con más de 45 comercios.
      </p>
      <p>
        Para formar parte de nuestro <b>CLUB</b> sólo tenes que{" "}
        <a href="#adherirse" onClick={handleShow}>
          ADHERIRTE.
        </a>
      </p>
      {show &&
        (window.innerWidth > 600 ? (
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Solicitud de adhesión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AdhesionForm handleClose={handleClose} />
            </Modal.Body>
          </Modal>
        ) : (
          <AdhesionForm handleClose={handleClose} />
        ))}
    </div>
  );
};

export default Info;
